<template>
    <div id="app">
        <v-app>
            <v-main>
                <router-view></router-view>
                <!--<exception v-if="$store.state.error" :error.sync="$store.state.error"></exception>-->
            </v-main>
        </v-app>
    </div>
</template>

<script>
    //import Exception from './components/Exception.vue'
    import { urlHelp }  from './mixins/helpers.js'

    export default {
        name: 'app',
        mixins: [ urlHelp ],
        //components: { Exception },
        //data() {
        //    return {
        //        //drawer: false,
        //        //isDark: false,
        //        //about: null
        //    }
        //},
        //ready() {
        //    var dropdown = document.getElementById("dropdown-theme");
        //    dropdown.dropdown();
        //    console.log(dropdown);
        //},
        //mounted() {
            //!!!! maybe put theme into store
            //this.setTheme();
            //this.getAbout();
        //},
        methods: {
            async getAbout() {
                //First check baseURL against the Prod URL! Throw error to catch us deploying it wrong.
                let axiosURL = this.snipFinalSlash(this.$http.defaults.baseURL).toUpperCase();
                let browserURL = this.snipFinalSlash(document.location.origin + document.location.pathname).toUpperCase();

                if (browserURL != axiosURL) {
                    console.log("BaseURL for API is pointed to another site. [" + axiosURL + "] != [" + browserURL + "]");
                    //let error = {
                    //    ClassName: "Bad Deployment",
                    //    Message: 
                    //}
                    //this.$store.dispatch("setError", error)
                    //return
                }

                //let response
                //try {
                //    response = await this.$http.get('/api/About')
                //} catch (ex) {
                //    this.$store.dispatch("setError", ex)
                //    return
                //}
                //this.about = response.data
            },
            //themeClicked: function (theme) {
            //    console.log(theme);
            //    //Theme colors need to exist at body level which is outside vue instance so we can't use v-bind:class directive on say the app id div
            //    document.body.classList.forEach(className => {
            //        if (className.startsWith("theme-")) {
            //            document.body.classList.replace(className, "theme-" + theme);
            //            return;
            //        }
            //    });
            //},
            //themeSwitchChanged: function () {
            //    console.log("themeSwitchChanged" + this.isDark);
            //    this.setTheme();
            //    this.$store.dispatch("saveUser");
            //},
            //setTheme() {
            //    if (this.isDark) {
            //        this.themeClicked("dark");
            //    } else {
            //        this.themeClicked("light");
            //    }
            //}
        }
    }
</script>


<style>

.validation{
    color: #de163e;
}

/* !!!! Move to seperate file and use import line here */

.v-btn { /* This fixes Vuetify making all buttons capitals and that can be eye straining */
  text-transform:none !important;
}

/* Vuetify's themeing scheme isn't great and they place theme--light many places that need overiding */
.theme--light.v-navigation-drawer, /* Hamburger Left Slide out Nav Drawer */
.theme--light.v-app-bar.v-toolbar.v-sheet{
    background-color: var(--color-bg-secondary) !important;
    color: var(--color-font-primary) !important;
}

/* Background on Menu's like dropdowns */
.v-menu__content.theme--light{
	background-color: var(--color-bg-menu);
}

/* Date Picker Body*/
.theme--light.v-picker__body{
    background-color: var(--color-bg-tiertary) !important;
}
.theme--light.v-text-field--filled>.v-input__control>.v-input__slot{
    background-color: var(--color-bg-tiertary) !important;
}

/* App */
.theme--light.v-application,
body {
    background-color: var(--color-bg-primary) !important;
    color: var(--color-font-primary) !important;
}
a{
    color: var(--color-link);
}
/* Check this section, some may not be needed anymore*/
nav{
    background-color: var(--color-bg-secondary);
}
.sidenav li>a{
    color: var(--color-font-primary);
}
.sidenav{
    background-color: var(--color-bg-primary);
}
.sidenav li>a:hover{
    background-color: var(--color-bg-highlight);
}
table.highlight>tbody>tr:hover {
    background-color: var(--color-bg-highlight);
}
tr{
    border-color: var(--color-line);
}

/*  V-Select Styling overrides to work with themes and stop Materialize from gunking it up*/
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    background: var(--color-bg-input);
    color: var(--color-font-primary);
    /*border: none;*/
    /*text-transform: lowercase;*/
    /*font-variant: small-caps;*/
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: var(--color-bg-input);
}
.style-chooser .vs__selected-options input[type=search]{
    border: none;
    color: var(--color-font-primary);
}
.style-chooser .vs__selected{
    background-color: var(--color-bg-input-item);
}
.input-field input[type=search]:focus:not(.browser-default){
	background-color: var(--color-bg-input);
    color: var(--color-font-primary);
}

.compact {
    transform: scale(.8);
    transform-origin: top;
}

/*MailTo Link Styles*/
a.mailto{
    text-decoration: none;
}
a:link.mailto{
    text-decoration: none;
}
a:visited.mailto{
    text-decoration: none;
}
a:hover.mailto{
    text-decoration: none;
}
a:active.mailto{
    text-decoration: none;
}
/* ========================  Print Styles  ======================== */
@media print{
    /*@page {
        size: landscape
    }*/
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
        color-adjust: exact !important;                 /*Firefox*/
    }

    .v-content, .v-main {
        padding: 0 !important;
    }
    nav, header{
        visibility: collapse;
    }

    .print-hide { 
        visibility: hidden; 
    }
    .print-collapse{
        visibility: collapse;
        width:0px;
        height:0px 
    }
    .print-break-after{
        page-break-after: always;
    }
}  

/* ========================  Themes  ======================== */
.theme-light {
   --color-bg-primary: #ffffff;
   --color-bg-secondary: #2b7abd;
   --color-bg-tiertary: #e1f1ff;
   --color-bg-highlight: rgba(0,0,0,0.04);
   --color-bg-highlight-strong: rgba(0,0,0,0.12);
   --color-bg-menu: #3292e2;
   
   --color-bg-input: #e3eef7;
   --color-bg-input-item: #e4dcfa;
   --color-bg-emboss: #aaaaaa;

   --color-font-primary: #000000;
   --color-font-secondary: #161616;

   --color-datepicker-select: rgb(255, 178, 40);
   --color-event: rgb(255, 178, 40);

   --color-line: rgba(0,0,0,0.12);

   --color-link: #0c4eed;
   
   --color-red-border: #ffbfbf;
   --color-red-background: #ffeded;
   --color-red-font: #a00000;
   --color-blue-border: #004bff;
   --color-blue-background: #abc7ff;
   --color-blue-font: #003b8d;
}
.theme-dark {
   --color-bg-primary: #272727;
   --color-bg-secondary: #256eac;
   --color-bg-tiertary: #1b3245;
   --color-bg-highlight: rgba(0,0,0,0.17);
   --color-bg-highlight-strong: rgba(0,0,0,0.12);
   --color-bg-menu: #3292e2;

   --color-bg-input: #513f7e;
   --color-bg-input-item: #b4a9ce;
   --color-bg-emboss: #161616;
   
   --color-event: rgb(255, 178, 40);

   --color-font-primary: #c8c8c8;
   --color-font-secondary: #ede3b0;

   --color-line: rgba(255,255,255,0.12);
   
   --color-link: #f3c93d;
   
   --color-red-border: #ffdfdf;
   --color-red-background: #dfe8ff;
   --color-red-font: #a00000;
   --color-blue-border: #004bff;
   --color-blue-background: #abc7ff;
   --color-blue-font: #003b8d;
}

.theme-lcars {
   --color-bg-primary: #000000;
   --color-bg-secondary: #ffcc66;
   --color-bg-highlight: rgba(255,255,255,0.12);

   --color-bg-input: #c0d5e8;
   --color-bg-input-item: #d2c3f6;
   
   --color-font-primary: #3366cc;
   --color-font-secondary: #006699;

   --color-line: rgba(255,255,255,0.12);
   

   --lcars1: #ffcc66;
   --lcars2: #ffff99;
   --lcars3: #ff9933;
   --lcars4: #664466;
   --lcars5: #cc99cc;
   --lcars6: #99ccff;
   --lcars7: #3366cc;
   --lcars8: #006699;
}
</style>
