import Vue from 'vue'
import Vuex from 'vuex'
import Axios from './axios'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        formBundle: null,
        error: null,
        isLoading: {
            user: false,
            formBundle: false
        }
    },
    mutations: {
        SET_ERROR(state, error) {
            state.error = error;
        },

        SET_LOADING_TRUE(state, loadvar) {
            state.isLoading[loadvar] = true;
        },
        SET_LOADING_FALSE(state, loadvar) {
            state.isLoading[loadvar] = false;
        },

        SET_FORMBUNDLE(state, bundle) {
            state.formBundle = bundle;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        UPDATE_OPERATORS(state, operator) {
            let index = state.formBundle.operators.findIndex((item) => {
                return item.id == operator.id;
            });
            if (index >= 0) {
                state.formBundle.operators[index] = operator;
            } else {
                state.formBundle.operators.push(operator);
            }
        },

    },
    actions: {
        //Exception Viewing
        setError({ commit }, error) {
            console.log(error);
            //console.log(error.response);
            //console.log(error.response.data);
            if (error.response && error.response.data.Message) {
                commit('SET_ERROR', error.response.data.ex);
            } else if (error.Message) {
                commit('SET_ERROR', error);
            }
        },

        //==============================
        
        async fetchFormBundle({ commit, dispatch }) {
            commit('SET_LOADING_TRUE', 'formBundle')
            commit('SET_FORMBUNDLE', null)

            let response
            try {
                response = await Axios.get('/api/FormBundle')
            } catch (ex) {
                dispatch("setError", ex)
                return
            }
            console.log(response.data);

            commit('SET_LOADING_FALSE', 'formBundle')
            commit('SET_FORMBUNDLE', response.data)
        },
        async fetchUser({ commit, dispatch }) {
            let response
            commit('SET_LOADING_TRUE', 'user')
            commit('SET_USER', { user: { role: -1 }})

            try {
                response = await Axios.get('api/myself')
            } catch (ex) {
                dispatch("setError", ex)
                return
            }

            commit('SET_LOADING_FALSE', 'user')
            commit('SET_USER', response.data)
        },
        async saveUser({ state, dispatch }) {
            console.log(state.user)
            try {
                await Axios.post('api/MyselfP', state.user) //, Email: "aaaaaaaa"
            } catch (ex) {
                dispatch("setError", ex)
                return
            }
        }
    },
    getters: {
        doneDataItems: state => {
            return state.dataItems.filter(item => item.active)
        }//,
        //isLoading: state => {
        //    return state.loadingStatus == 'loading'
        //}
    }

})

//https://stackoverflow.com/questions/40564071/how-do-i-break-up-my-vuex-file