import Vue from 'vue'
import Axios from './axios'
import App from './App.vue'
import store from './store'
import router from './routes'
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import 'typeface-roboto/index.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'


Vue.use(Vuetify);
const vuetify = new Vuetify({
    theme: {
        disable: true
    },
    icons: {
        iconfont: 'mdi',
    },
});

Vue.prototype.$http = Axios;
Vue.config.productionTip = false;

//Vue.directive('click-outside', {
//    bind: function (el, binding, vnode) {
//        el.clickOutsideEvent = function (event) {
//            // here I check that click was outside the el and his children
//            if (!(el == event.target || el.contains(event.target))) {
//                // and if it did, call method provided in attribute value
//                vnode.context[binding.expression](event);
//            }
//        };
//        document.body.addEventListener('click', el.clickOutsideEvent)
//    },
//    unbind: function (el) {
//        document.body.removeEventListener('click', el.clickOutsideEvent)
//    },
//});

//These directives used with the time selection component
Vue.directive('mousemove-anywhere', {
    bind: function (el, binding, vnode) {
        el.mouseMoveAnywhereEvent = function (event) {
            vnode.context[binding.expression](event);
        };
        document.body.addEventListener('mousemove', el.mouseMoveAnywhereEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('mousemove', el.mouseMoveAnywhereEvent)
    },
});
Vue.directive('mouseup-anywhere', {
    bind: function (el, binding, vnode) {
        el.mouseUpAnywhereEvent = function (event) {
            vnode.context[binding.expression](event);
        };
        document.body.addEventListener('mouseup', el.mouseUpAnywhereEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('mouseup', el.mouseUpAnywhereEvent)
    },
});

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: h => h(App),
    //created() {
    //    this.$store.dispatch("fetchUser")
    //}
})

