import Vue from 'vue';
import VueRouter from 'vue-router';
//import store from './store'

import DeadEnd from './components/DeadEnd.vue'
//import EditUsers from './components/edit/EditUsers.vue'

import LandingVue from './components/Landing.vue';

Vue.use(VueRouter);

//https://css-tricks.com/protecting-vue-routes-with-navigation-guards/

//async function requiresRole(to, from, next) {
//    if (store.state.user == null) {
//        try {
//            await store.dispatch("fetchUser");
//        } catch (e) {
//            console.log("catch: something when wrong looking for role");
//            next({
//                name: "unauthorized" // back to safety route //!!!! or error route
//            })
//        }
//    }
//    let authorized = store.state.user.isActive && to.meta(store.state.user);
//    if (authorized) {
//        next()
//    } else {
//        next({
//            name: "unauthorized", // back to safety route //
//            query: { redirectFrom: to.fullPath, returnTo: from.fullPath }
//        })
//    }
//}


//async function homeRedirect(to, from, next) {
//    if (store.state.user == null) {
//        try {
//            await store.dispatch("fetchUser");
//        } catch (e) {
//            console.warn(e);
//            next({ name: "unauthorized" })
//            return;
//        }
//    }
//    if (store.state.user.isActive) {
//        next("/request/")
//    } else {
//        next({ name: "unauthorized" })
//    }
//}

const routes = [
    //{ path: '/', beforeEnter: homeRedirect },
    { path: '/', name: "edituser", component: LandingVue },

    //{ path: "/edit/users", name: "edituser", component: EditUsers, beforeEnter: requiresRole, meta: (user) => { return user.isAdmin } },
    //{ path: "/request", name: "request", component: Request, beforeEnter: requiresRole, meta: (user) => { return user.isAdmin } },
    //{ path: "/notification", name: "notification", component: Notification, beforeEnter: requiresRole, meta: (user) => { return user.isAdmin } },

    {
        path: '/unauthorized', name: "unauthorized", component: DeadEnd,
        meta: {
            heading: "You are not authorized.",
            message: "Sorry, you don't have the permissions to reach the route requested. Contact an admin in order to get access.",
            icon: "lock"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: "DeadEnd",
        component: DeadEnd,
        meta: { heading: "Nothing here", message: "Check the addressed route for typos", icon: "error" }
    }
];

export default new VueRouter({
    routes
});
