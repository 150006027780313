<template>
    <div class="DeadEnd text-center" v-if="model">
        <v-icon v-if="model.icon" style="font-size: 200px; color: var(--color-bg-emboss)" class="mt-8">{{ model.icon }}</v-icon>
        <h1 class="mt-4">{{ model.heading }}</h1>
        <p>{{ model.message }}</p>
        <router-link v-if="safety" :to="{ path:safety}">Return</router-link>
    </div>
</template>

<script>
    export default {
        name: 'DeadEnd',
        data() {
            return {
                model: null,  //heading, message, icon,
                from: "",
                safety: ""
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.model = to.meta;
                if (to.query.safety) { //if you want to set different from/safeties in query
                    vm.from = to.query.redirectFrom;
                    vm.safety = to.query.returnTo;
                } else {
                    vm.from = to.fullPath;
                    vm.safety = from.fullPath;
                }
            })
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .Unauthorized {
        text-align: center;
    }

    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    /*a {
        color: #42b983;
    }*/
</style>
