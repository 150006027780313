
export const urlHelp = {
    methods: {
        snipFinalSlash(urlstr) {
            if (urlstr[urlstr.length - 1] == '/') {
                urlstr = urlstr.substr(0, urlstr.length - 1);
            }
            return urlstr;
        },
    }
}

export const militaryTime = {
    methods: {
        toMilTime: function (date) {
            var full = new Date(date);
            return full.getHours().toString().padStart(2, '0') + full.getMinutes().toString().padStart(2, '0')
        },
        toMilTimeRange: function (startDate, endDate) {
            //Account for 0000 on endDate equals 2400 on day before, javascript/json get confused otherwise
            let edate = new Date(endDate);
            if (edate.getSeconds() + edate.getMinutes() + edate.getHours() == 0) {
                return this.toMilTime(startDate) + "-2400";
            }
            else {
                return this.toMilTime(startDate) + '-' + this.toMilTime(endDate);
            }
        },
        isValidMilTime: function (milTimeString) {
            if (milTimeString.length != 4)
                return false;

            let hour = milTimeString.substr(0, 2);
            let minute = milTimeString.substr(2, 4);
            if (!this.isNumeric(hour) || !this.isNumeric(minute)) {
                return false;
            }
            let hourint = parseInt(hour);
            let minuteint = parseInt(minute);

            if (hourint < 0 || hourint > 24)
                return false;
            if (minuteint < 0 || minuteint > 59)
                return false;

            return true;
        },
        isNumeric: function (str) { //!!!! go somewhere else? more global????
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str)) 
        },
        isInt: function(value) {
            return !isNaN(value) &&
                parseInt(Number(value)) == value &&
                !isNaN(parseInt(value, 10));
        }
    }
}

export const dateHelp = {
    methods: {
        parseISODate() {
            var doo = new Date(this.scheduleDate);
            return new Date(doo.getTime() - doo.getTimezoneOffset() * -60000);
        },
        makeISODate(date) {
            return date.getFullYear() + '-' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + date.getDate()).slice(-2);
        },
        stringIsValidISODate: function (str) {
            if (str.length != 10 || !/\d{4}-\d{2}-\d{2}/.test(str)) return false;
            var d = new Date(str);
            return d.toISOString().substr(0,10) === str;
        }
    }
}

export const readableDate = {
    methods: {
        readableDate: function (str) {
            return (new Date(str)).toLocaleDateString("en-US");
        }
    }
}
